import { SyntheticEvent } from 'react';
import { slugify } from 'underscore.string';
import PostLink from 'src/modules/shared/links/postLink';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { ResultsPostFooterReplies } from 'styles-js/resultsList';
import { Community, CommunityListPost, ElasticSearchPost, PostType } from 'src/constants/types';

type Props = {
  community: Community | ElasticSearchPost['community'];
  post: CommunityListPost | ElasticSearchPost;
  onPostClick: (type: string) => void;
}
export default function PostItemReplies({ post, onPostClick, community }: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ context }) => context.currentUser);
  const isVisitorNoRepliesLink = !currentUser && post.totalResponses === 0;
  const onClickPost = (e: SyntheticEvent) => {
    onPostClick('number-of-replies');
    if (isVisitorNoRepliesLink) {
      if (e) e.preventDefault();
      dispatch(showSignupModal());
    }
  };
  if ('isLocked' in post && post.isLocked && post.totalResponses === 0) return null;
  return (
    <PostLink
      anchorToResponses={true}
      communitySlug={community?.slug}
      disabled={isVisitorNoRepliesLink}
      isPrivate={(post as CommunityListPost)?.isPrivate}
      postId={post.postId}
      postTypeId={PostType.Post}
      urlEncodedTitle={slugify(post.title)}
      onClick={onClickPost}
    >
      {post.totalResponses > 0
        ? <ResultsPostFooterReplies>{post.totalResponses} {post.totalResponses === 1 ? i18n.t('Reply') : i18n.t('Replies')}</ResultsPostFooterReplies>
        : <>{'isArchived' in community && !community.isArchived && i18n.t('Be the first to reply')}</>}
    </PostLink>
  );
}

import { ReactNode } from 'react';
import Link from 'next/link';

type CommunityLink = {
  name: string;
  slug: string;
  onClick?: () => void;
  children?: ReactNode;
}

export default function CommunityLink({ name, slug, children, onClick }: CommunityLink) {
  return (
    <Link
      aria-label={name}
      href={`/${slug}`}
      onClick={onClick}
    >
      {children || name}
    </Link>
  );
}
